<template>
  <div class="home">
    <!-- 头部 -->
    <div
      class="topBox"
      style="
        display: flex;
        align-items: center;
        padding: 0 40px 0 10px;
        box-sizing: border-box;
        height: 100px !important;
      "
    >
      <!-- 前台 -->
      <div
        v-if="TokenData.Role == 'teacher'"
        class="topLeftBox"
        style="width: 140px; height: 100px; display: flex; align-items: center"
      >
        <img
          src="http://image.mengqiyun.com/jiaoanlogo.png"
          style="
            background-color: #fff;
            width: 140px;
            height: 60px;
            border-radius: 10px;
          "
          alt=""
        />
      </div>

      <!-- 后台 -->
      <div
        v-if="TokenData.Role == 'admin'"
        class="topLeftBox"
        style="width: 260px; height: 100px; display: flex; align-items: center"
      >
        <img
          src="http://image.mengqiyun.com/jiaoanlogo.png"
          style="
            background-color: #fff;
            width: 200px;
            height: 100px;
            border-radius: 10px;
            margin: 0 auto;
          "
          alt=""
        />
      </div>

      <div style="display: flex; align-items: center">
        <div style="width: 40px; height: 40px">
          <!-- <img src="http://image.mengqiyun.com/hhhhh.jpg" style="background-color: #01B37C;width: 40px;height: 40px;border-radius: 50%;" alt=""> -->
          <img
            src="http://image.mengqiyun.com/snipaste20230328_095606.png"
            style="
              background-color: #01b37c;
              width: 40px;
              height: 40px;
              border-radius: 50%;
            "
            alt=""
          />
        </div>
        <span style="margin-left: 10px; font-size: 16px; color: #333">{{
          TokenData.UserName
        }}</span>
        <span style="margin-left: 20px; font-size: 16px; color: #333"
          >{{ year }}年{{ month }}月{{ date }}号</span
        >
        <span style="margin-left: 10px; font-size: 16px; color: #333">{{
          day
        }}</span>
        <div class="topRightBox topPos" style="margin-left: 20px">
          <el-dropdown @command="menuFn">
            <span class="el-dropdown-link">
              <!-- <i class="el-icon-s-tools"></i> -->
              <img
                style="width: 20px; height: 20px"
                src="http://image.mengqiyun.com/%E8%AE%BE%E7%BD%AE%20%281%29.png"
                alt=""
              />
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="a">首页</el-dropdown-item> -->
              <el-dropdown-item v-if="TokenData.Role == 'teacher'" command="b"
                >修改密码</el-dropdown-item
              >
              <!-- <el-dropdown-item v-if="TokenData.Role=='admin'" command="b">修改密码</el-dropdown-item> -->
              <el-dropdown-item command="c">退出系统</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="contentBox">
      <!-- 后台 左边  -->
      <div v-if="TokenData.Role == 'admin'" class="contentLeftBox1">
        <el-radio-group
          v-model="isCollapse"
          style="margin-bottom: 10px; margin-top: 2px; margin-left: 10px"
        >
        </el-radio-group>

        <div
          style="
            margin-top: 0px;
            font-size: 24px;
            font-weight: 600;
            width: 100%;
            padding-left: 50px;
            box-sizing: border-box;
            margin-bottom: 40px;
          "
        >
          教案管理系统
        </div>

        <el-menu
          class="el-menu-vertical-demo"
          style="width: 260px"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
          active-text-color="#01B37C"
        >
          <el-menu-item index="3" @click="TeacherFn">
            <i class="el-icon-s-custom"></i>
            <span style="font-size: 18px" slot="title">教师管理</span>
          </el-menu-item>

          <el-menu-item index="51" @click="ClassFn">
            <i class="el-icon-s-claim"></i>
            <span style="font-size: 18px" slot="title">教案分类</span>
          </el-menu-item>
          <!-- <el-menu-item index="52" @click="SrcFn">
            <i class="el-icon-s-claim"></i>
            <span style="font-size: 18px" slot="title">素材分类</span>
          </el-menu-item> -->

          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-unfold"></i>
              <span style="font-size: 18px" slot="title">排课管理</span>
            </template>
            <el-menu-item-group>
              <!-- <span slot="title">分组一</span> -->
              <el-menu-item
                @click="TimeListFn"
                style="
                  padding-left: 40px;
                  box-sizing: border-box;
                  width: 100%;
                  margin-left: 40px;
                  font-size: 16px;
                  width: 220px;
                "
                index="1-1"
                >实时列表</el-menu-item
              >
              <el-menu-item
                @click="ClassListFn"
                style="
                  padding-left: 40px;
                  box-sizing: border-box;
                  width: 100%;
                  margin-left: 40px;
                  font-size: 16px;
                  width: 220px;
                "
                index="1-2"
                >课程列表</el-menu-item
              >
              <el-menu-item
                @click="RoveFn"
                style="
                  padding-left: 40px;
                  box-sizing: border-box;
                  width: 100%;
                  margin-left: 40px;
                  font-size: 16px;
                  width: 220px;
                "
                index="1-3"
                >课程审批</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-menu-item index="5" @click="tableFn">
            <i class="el-icon-picture-outline"></i>
            <span style="font-size: 18px" slot="title">教案管理</span>
          </el-menu-item>
          <!-- <el-menu-item index="9" @click="materialFn">
            <i class="el-icon-picture-outline"></i>
            <span style="font-size: 18px" slot="title">素材管理</span>
          </el-menu-item> -->
          <el-menu-item index="6" @click="DoorFn">
            <i class="el-icon-s-shop"></i>
            <span style="font-size: 18px" slot="title">门店管理</span>
          </el-menu-item>
        </el-menu>
      </div>

      <!-- 前台 左边 -->
      <div v-if="TokenData.Role == 'teacher'" class="contentLeftBox2">
        <el-radio-group
          v-model="isCollapse"
          style="margin-bottom: 10px; margin-top: 2px; margin-left: 10px"
        >
        </el-radio-group>
        <el-menu
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
        >
          <div
            @click="clickLeftFn(index)"
            v-for="(item, index) in leftTabData"
            :key="index"
            ref=""
            style="
              text-align: center;
              width: 160px;
              height: 120px;
              padding: 20px;
              box-sizing: border-box;
            "
          >
            <!-- <i class="el-icon-user"></i> -->
            <!-- leftIndex==index?leftChooseClass:leftNoClass -->
            <div
              class="leftTabClass"
              :style="[
                { backgroundColor: leftIndex == index ? '#EAF9F4' : '#fff' },
                { color: leftIndex == index ? '#01B37C' : '#666' },
              ]"
            >
              <img
                :src="leftIndex == index ? item.imgTrue : item.imgFlase"
                style="width: 26px; height: 26px; margin-top: 5px"
                alt=""
              />
              <div style="font-size: 16px" slot="title">{{ item.name }}</div>
            </div>
          </div>
        </el-menu>
      </div>

      <div class="contentRightBox">
        <router-view />
      </div>
    </div>

    <el-dialog title="发布公告" :visible.sync="dialogVisible1" width="35%">
      <el-form ref="form" :model="messageForm" label-width="80px">
        <el-form-item label="公告内容">
          <el-input v-model="messageForm.message"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="Sure">发 布</el-button>
      </span>
    </el-dialog>

    <!--修改密码 废-->
    <el-dialog
      v-if="false"
      title="修改密码"
      style="z-index: 2"
      :visible.sync="dialogVisible2"
      width="350px"
    >
      <el-form ref="form" :model="form3" label-width="80px">
        <!-- <el-form-item label="旧密码">
          <el-input v-model="form3.oldpwd"></el-input>
        </el-form-item> -->
        <el-form-item label="新密码">
          <el-input v-model="form3.newPwd"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="ResertPassWordFn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码 教师修改自己 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible2" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              新密码
            </div>
            <el-input
              v-model="form3.newPwd"
              style="flex: 1"
              placeholder="请输入"
            />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="dialogVisible2 = false"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="ResertPassWordFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 修改密码 管理员修改自己 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible3" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              新密码
            </div>
            <el-input
              v-model="form4.newPwd"
              style="flex: 1"
              placeholder="请输入"
            />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="dialogVisible4 = false"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="ResertPassWordFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetUserInfo,
  SetNote,
  ResertPassWordsword,
  AddServiceIP,
  SetDemo,
  GetDemo,
  DelServiceIP,
  GetAllServiceIP,
  SetUrl,
  SetPrice,
  SetNetworkLinks,
  GenerateCamilo,
  SetlocalDeployment,
  SettheAgentTestLink,
  getWebNetworkLinks,
  setWebNetworkLinks,
  getSDDSNetworkLinks,
  setWebSDASDNetworkLinks,
  getdawdwazxNetworkLinks,
  setWebdajiwNetworkLinks,
  getgkyukyuNetworkLinks,
  setadadaxzbnNetworkLinks,
  GetUrl,
  GetlocalDeployment,
  GettheAgentTestLink,
  GetPrice,
  GetVersionAndUrl,
  SetVersion,
  GetAppTeach,
  GetAppDwonUrl,
  GetVNCNetWorkLinks,
  GetVNCAppDwonUrl,
  SetAPPbuy,
  SetMoney,
  GetAppbuy,
  GetMoney,
  SetAPPTeach,
  SetAppDwonUrl,
  SetVNCNetworkLinks,
  SetVNCAppDwonUrl,
  ResertPassWord,
} from "@/request/api.js";

export default {
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      contentBool: true,
      ifDis: false,
      defaultActive: "0",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      messageForm: {},
      form3: {
        newPwd: "",
      },
      form4: {
        newPwd: "",
      },
      AddForm: {},
      DeleteForm: {},
      setExampleForm: {},
      tableData2: "",

      selectLetterValue: "",
      Nowurl: "",

      Allurl: [],

      setAgent: {
        AgentURL: "",
        BuyVNCUrl: "",
        BuyVNCAPPBtnUrl: "", //购买VNCAPP按钮链接
      },

      setLinks: {},

      Type: "天数",

      SetCard: {},

      allKey: [],

      showCopy: false,

      netWorkLink: "",
      webSDASDNet: "",
      localhostLink: "",
      adadaxzbnLink: "",

      //教案
      leftIndex: 0,
      leftTabData: [
        {
          name: "排课",
          imgFlase: "http://image.mengqiyun.com/paike%403x.png",
          imgTrue: "http://image.mengqiyun.com/paike_xuanzhong%403x.png",
        },
        {
          name: "课程列表",
          imgFlase: "http://image.mengqiyun.com/kecliebiao%403x.png",
          imgTrue:
            "http://image.mengqiyun.com/kechengliebiao_xuanzhong%403x.png",
        },
        {
          name: "个人中心",
          imgFlase: "http://image.mengqiyun.com/gerenzhongxin%403x.png",
          imgTrue:
            "http://image.mengqiyun.com/gerenzhongxin_xuanzhong%403x.png",
        },
      ],
      leftChooseClass: " background-color: #EAF9F4;color: #01B37C;",
      leftNoClass: " background-color: #fff;color:#666;",
      TokenData: "",
      year: "",
      month: "",
      day: "",
      date: "",
    };
  },
  methods: {
    //强制刷新
    // change() {
    //   this.$forceUpdate(); //强制刷新
    // },

    // 教案
    clickLeftFn(e) {
      this.leftIndex = e;
      if (e == 0) {
        this.$router.push("/home/Index");
        // this.endTimeFn();
      }
      if (e == 1) {
        this.$router.push("/home/Keys");
        // this.endTimeFn();
      }
      if (e == 2) {
        this.$router.push("/home/User");
        // this.endTimeFn();
      }
      if (e == 3) {
        this.$router.push("/home/VNC");
        // this.endTimeFn();
      }
    },

    endTimeFn() {
      // return;
      //过期自动退出登录
      // 当前时间戳
      let nowTime = new Date().getTime();
      console.log("当前时间戳==>", nowTime);
      //上次登录时间戳
      let storageTime = localStorage.getItem("storageTime");
      console.log("上次登录时间戳==>", storageTime);
      //过期时间戳
      let endTime = Number(storageTime) + 1000 * 3600 * 24 * 5;
      // let endTime = Number(storageTime) + 5000;
      console.log("过期时间戳==>", endTime);
      if (nowTime > endTime) {
        // console.log("过期了");
        localStorage.clear();
        this.$router.push("/");
      } else {
        // console.log("没过期");
      }
    },
    // gotoLogin() {
    //   let tokens = localStorage.getItem("token");
    //   if (this.TokenData.Token !== tokens) {
    //     localStorage.clear()
    //     this.$router.push("/");
    //   }
    // },
    Example() {
      this.$router.push("/home/Example");
    },
    IndexFn() {
      this.$router.push("/home/Index");
      console.log("哈哈哈");
      this.endTimeFn();
    },
    UserFn() {
      this.$router.push("/home/User");
      this.endTimeFn();
    },
    TeacherFn() {
      this.$router.push("/home/ApiData");
      this.endTimeFn();
    },
    ClassFn() {
      this.$router.push("/home/AppDistinguish");
      this.endTimeFn();
    },
    SrcFn() {
      this.$router.push("/home/SrcDistinguish");
      this.endTimeFn();
    },
    DoorFn() {
      this.$router.push("/home/Recharge");
      this.endTimeFn();
    },
    tableFn() {
      this.$router.push("/home/BuyHistory");
      this.endTimeFn();
    },
    materialFn() {
      this.$router.push("/home/MaterialManage");
      this.endTimeFn();
    },
    TimeListFn() {
      this.$router.push("/home/Agent");
      this.endTimeFn();
    },
    ClassListFn() {
      this.$router.push("/home/NetCheck");
      this.endTimeFn();
    },
    RoveFn() {
      this.$router.push("/home/VNC");
      this.endTimeFn();
    },
    SetUserFn() {
      this.$router.push("/home/UserManager");
      this.endTimeFn();
    },
    SetStoreFn() {
      this.$router.push("/home/StoreManager");
      this.endTimeFn();
    },
    GetAllInfoFn() {
      this.$router.push("/home/GetAllRecharge");
      this.endTimeFn();
    },
    netCheck() {
      this.$router.push("/home/NetCheck");
      this.endTimeFn();
    },
    VNCFn() {
      this.$router.push("/home/VNC");
      this.endTimeFn();
    },
    SetAllKeys() {
      this.$router.push("/home/Keys");
      this.endTimeFn();
    },
    SetAgentFn() {
      this.dialogVisible5 = true;
    },
    SetNetworkLinks() {
      this.dialogVisible6 = true;
    },
    sureCard() {
      GenerateCamilo({
        Type: this.SetCard.type,
        CardNum: this.SetCard.cardNum,
        Num: this.SetCard.num,
      }).then((res) => {
        this.allKey = res.Data;
        if (res.Code === 2000) {
          this.showCopy = true;
          this.$message({
            message: "生成成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
        this.dialogVisible7 = false;
      });
    },
    choose1() {
      console.log(this.SetCard.type);
      if (this.SetCard.type === "0") {
        this.Type = "天数";
      } else if (this.SetCard.type === "1") {
        this.Type = "次数";
      }
    },

    Choose() {
      this.Nowurl = this.selectLetterValue;
    },

    //修改密码
    ResertPassWordFn() {
      let that = this;
      if (this.form3.newPwd == "") {
        this.$message({
          message: "请输入新密码",
          type: "error",
        });
        return;
      }
      ResertPassWord(this.form3).then((res) => {
        if (res.Code === 2000) {
          this.PwdSuccessMessage();
          that.form3.newPwd = "";
        } else {
          this.PwdErrorMessage();
        }
        this.dialogVisible2 = false;
      });
    },
    //发布消息
    Sure() {
      SetNote({ note: this.messageForm.message }).then((res) => {
        if (res.Code === 2000) {
          this.SuccessMessage();
        } else {
          this.ErrorMessage();
        }
        this.reload();
      });
    },
    SuccessMessage() {
      this.$message({
        message: "发布成功",
        type: "success",
      });
    },
    ErrorMessage() {
      this.$message({
        message: "发布失败",
        type: "error",
      });
    },
    PwdSuccessMessage() {
      this.$message({
        message: "修改成功",
        type: "success",
      });
    },
    PwdErrorMessage() {
      this.$message({
        message: "修改失败",
        type: "error",
      });
    },
    AddSuccessMessage() {
      this.$message({
        message: "添加成功",
        type: "success",
      });
    },
    AddErrorMessage() {
      this.$message({
        message: "添加失败",
        type: "error",
      });
    },
    menuFn(e) {
      if (e === "a") {
        this.contentBool = true;
        this.$router.push("/home/Index");
      } else if (e === "c") {
        this.$router.push("/");
        localStorage.clear();
      } else if (e === "b") {
        this.dialogVisible2 = true;
      } else if (e === "d") {
        this.dialogVisible3 = true;
      }
      // console.log(111);
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },

  created() {
    //获取当前用户权限
    this.endTimeFn();
    let data = JSON.parse(localStorage.getItem("TokenData"));
    console.log("data==>", data);
    this.TokenData = data;
    // this.gotoLogin();
    let IsManager = this.TokenData.IsManager;
    if (this.TokenData.Role === "teacher") {
      if (IsManager) {
        this.leftTabData = [
          {
            name: "排课",
            imgFlase: "http://image.mengqiyun.com/paike%403x.png",
            imgTrue: "http://image.mengqiyun.com/paike_xuanzhong%403x.png",
          },
          {
            name: "课程列表",
            imgFlase: "http://image.mengqiyun.com/kecliebiao%403x.png",
            imgTrue:
              "http://image.mengqiyun.com/kechengliebiao_xuanzhong%403x.png",
          },
          {
            name: "个人中心",
            imgFlase: "http://image.mengqiyun.com/gerenzhongxin%403x.png",
            imgTrue:
              "http://image.mengqiyun.com/gerenzhongxin_xuanzhong%403x.png",
          },
          {
            name: "课程审批",
            imgFlase: "http://image.mengqiyun.com/kecliebiao%403x.png",
            imgTrue:
              "http://image.mengqiyun.com/kechengliebiao_xuanzhong%403x.png",
          },
        ];
      } else {
        this.leftTabData = [
          {
            name: "排课",
            imgFlase: "http://image.mengqiyun.com/paike%403x.png",
            imgTrue: "http://image.mengqiyun.com/paike_xuanzhong%403x.png",
          },
          {
            name: "课程列表",
            imgFlase: "http://image.mengqiyun.com/kecliebiao%403x.png",
            imgTrue:
              "http://image.mengqiyun.com/kechengliebiao_xuanzhong%403x.png",
          },
          {
            name: "个人中心",
            imgFlase: "http://image.mengqiyun.com/gerenzhongxin%403x.png",
            imgTrue:
              "http://image.mengqiyun.com/gerenzhongxin_xuanzhong%403x.png",
          },
        ];
      }
    }

    let time = new Date();
    this.year = time.getFullYear();
    this.month = time.getMonth() + 1;
    this.date = time.getDate();
    let day = time.getDay();
    if (day == 0) {
      this.day = "星期日";
    } else if (day == 1) {
      this.day = "星期一";
    } else if (day == 2) {
      this.day = "星期二";
    } else if (day == 3) {
      this.day = "星期三";
    } else if (day == 4) {
      this.day = "星期四";
    } else if (day == 5) {
      this.day = "星期五";
    } else if (day == 6) {
      this.day = "星期六";
    }
    return;
    GetUserInfo().then((res) => {
      // console.log(res);
      console.log("reshh==>", res.Code);
      if (res.Code == 2000) {
        if (res.Data.Role === "admin") {
          this.ifDis = true;
          getWebNetworkLinks().then((res) => {
            this.netWorkLink = res.Data;
          });
          getSDDSNetworkLinks().then((res) => {
            this.webSDASDNet = res.Data;
          });
          getdawdwazxNetworkLinks().then((res) => {
            this.localhostLink = res.Data;
          });
          getgkyukyuNetworkLinks().then((res) => {
            this.adadaxzbnLink = res.Data;
          });

          //获取链接
          this.GetUrlFn(); //服务器部署链接
          this.GetlocalDeploymentFn(); //获取本地服务器部署链接
          this.GettheAgentTestLinkFn(); //获取代理测试链接
          this.GetPriceFn(); //获取代理购买价格
          this.GetVersionAndUrlFn(); //设置网络验证版本和验证链接
          this.GetAppTeachFn();
          this.GetAppbuyFn();
          this.GetMoneyFn(); //获取app扣费金额
          this.GetAppDwonUrlFn(); //获取app下载链接
          this.GetVNCNetworkLinksFn(); //获取VNC购买跳转链接
          this.GetVNCAppDwonUrlFn(); //获取VNC购买跳转链接
        }
      } else {
        console.log("退出");
        this.$router.push("/");
      }
    });
    // GetAllServiceIP().then((res) => {
    //   this.Allurl = res.Data;
    //   this.Nowurl = this.selectLetterValue;
    // });
    //获取所有服务器
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: pink; */
  //头部
  .topBox {
    min-width: 100%;
    background-color: #fff;
    display: flex;
    padding-left: 15px;
    height: 100px;
    justify-content: space-between;
    box-sizing: border-box;

    .topRightBox {
      /* margin-top: 15px; */
      margin-right: 10px;
      /* color: #fff !important; */

      .el-dropdown-link {
        color: #fff;
      }

      .el-icon-s-tools {
        font-size: 25px;
        line-height: 200%;
      }
    }
  }

  .contentBox {
    //flex布局
    display: flex;
    flex: 1;
    //高度计算
    //height: calc(100vh - 60px);
    background-color: #f7f8fa;

    .contentLeftBox1 {
      // border-right: 1px solid #ccc;
      width: 260px;
      background-color: #fff;
      border-radius: 10px;
      .leftChooseClass {
        background-color: #eaf9f4;
        color: #01b37c;
      }
      .leftNoClass {
        background-color: #fff;
        color: #666;
      }
      .leftTabClass {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        box-sizing: border-box;
        cursor: pointer;
        height: 80px;
      }
    }

    .contentLeftBox2 {
      // border-right: 1px solid #ccc;
      width: 160px;
      background-color: #fff;
      border-radius: 10px;
      .leftChooseClass {
        background-color: #eaf9f4;
        color: #01b37c;
      }
      .leftNoClass {
        background-color: #fff;
        color: #666;
      }
      .leftTabClass {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        box-sizing: border-box;
        cursor: pointer;
        height: 80px;
      }
    }

    .contentRightBox {
      margin: 20px;

      //flex分布 左右两边弹性
      flex: 1;
      padding: 20px;
      min-width: 75%;
      background-color: #fff;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 120px;
  // background-color: ;
  min-height: 200px;
}

.el-menu {
  border: none;
}

//头部logo位置
.topPos {
  margin-top: 5px;
}
</style>
